import React from "react";

const Footer = () => {

    return (

        <div className="footer" style={{ paddingBottom: "0px !important" }}>

            <div className="container">
                <div className="footer_wrapper_first">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                            <div className="wrapper_first_image">
                                <img src="images/AL-SHIFA-LOGO-BLACK-BLUE-SHADE.png" className="img-responsive" alt="footer3_logo_img" style={{ width: "157px", height: "100px" }} />
                            </div>
                        </div>
                        <div className="col-sm-6 visible-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-map-marker"></i><span>Get Appointment</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <i className="fa fa-phone"></i><span>Contact our Office</span>
                            </div>
                            <div className="wrapper_first_icon">
                                <i className="fa fa-rss" aria-hidden="true"></i><span>Latest News New</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-map-marker"></i><span>Get Appointment</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-phone"></i><span>Contact our Office</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12 hidden-sm">
                            <div className="wrapper_first_icon">
                                <i className="fa fa-rss" aria-hidden="true"></i><span>Latest News New</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="footer_wrapper_second">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_about">
                                <h4>About Us</h4>
                                <div className="abotus_content">
                                    <p>Proin gravida nibh vel velit auctr aliquet. Aenean sollicitudin, lorem quis bibendum
                                        auctor, nisi elit aks consequat vel velit auctor....</p>
                                </div>
                                <div className="aboutus_link">Read More<i className="fa fa-caret-right" aria-hidden="true"></i>
                                </div>
                                <ul className="aboutus_social_icons">
                                    <li><i className="fa fa-facebook"></i>
                                    </li>
                                    <li><i className="fa fa-twitter"></i>
                                    </li>
                                    <li><i className="fa fa-youtube-play" aria-hidden="true"></i>
                                    </li>
                                    <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_useful">
                                <h4>Useful Link</h4>
                                <ul>
                                    <li><i className="fa fa-caret-right" aria-hidden="true"></i> Ipsum. Proin
                                        gravida nibh vel
                                    </li>
                                    <li><i className="fa fa-caret-right" aria-hidden="true"></i> Auctor aliquet.
                                        Aenean
                                    </li>
                                    <li><i className="fa fa-caret-right" aria-hidden="true"></i> Din, lorem quis
                                        bibendum
                                    </li>
                                    <li><i className="fa fa-caret-right" aria-hidden="true"></i> Nisi elit consequat
                                        ipsum
                                    </li>
                                    <li><i className="fa fa-caret-right" aria-hidden="true"></i> Sagittis sem nibh
                                        id elit. </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_blog">
                                <h4>From the Blog</h4>
                                <div className="blog_wrapper1">
                                    <div className="blog_image">
                                        <img src="images/home/home-2/blog-img1.png" className="img-responsive"
                                            alt="blog-img1_img" />
                                    </div>
                                    <div className="blog_text">
                                        <h5>Last Booking Registration Date</h5>
                                        <div className="blog_date"><i className="fa fa-calendar-o" aria-hidden="true"></i>June 28,
                                            2022-23</div>
                                    </div>
                                </div>
                                <div className="blog_wrapper2">
                                    <div className="blog_image">
                                        <img src="images/home/home-2/blog-img2.png" className="img-responsive"
                                            alt="blog-img2_img" />
                                    </div>
                                    <div className="blog_text">
                                        <h5>Learn Every Thing you Want</h5>
                                        <div className="blog_date"><i className="fa fa-calendar-o" aria-hidden="true"></i>June 28,
                                            2022-23</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                            <div className="wrapper_second_contact">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li><i className="fa fa-map-marker"></i>
                                        <p>16122 Collins Street West
                                            <br />Victoria 8007 Australia.
                                        </p>
                                    </li>
                                    <li><i className="fa fa-phone"></i>
                                        <p>+1 854 584 5475
                                            <br />+1 547 547 5487
                                        </p>
                                    </li>
                                    <li><i className="fa fa-envelope"></i>contact@example.com
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div className="copyright_content">
                                <p>© Copyright 2023-24 by  Al Shifa Medical Supplies LLC  - all right reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

}

export default Footer;